/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.primary-secondardy-gradient-background {
    background: linear-gradient(0deg, rgba(195,34,133,1) 0%, rgba(253,106,45,1) 100%);
}

.primary-secondardy-gradient-text {
    background-color: rgba(253,106,45,1) 0%;
    background-image: linear-gradient(45deg, rgba(253,106,45,1) 0%, rgba(195,34,133,1) 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}
body {
  font-family: 'Inter', sans-serif
}

/* GRID */

.an-header {
  grid-area: header;
  /* background-color: orchid; */
}

.an-main {
  grid-area: main;
  width: 100%;
  /* background-color: tomato; */
  /* height: 1100px; */
}

.an-sidebar {
  /* grid-area: sidebar; */
  background: rgb(195,34,133);
  background: linear-gradient(0deg, rgba(195,34,133,1) 0%, rgba(253,106,45,1) 100%);
  height: 100vh;
  width: 200px;
  box-shadow: -3px 0 10px 0 #555;
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
}

.an-footer {
  grid-area: footer;
  /* background-color: lightgreen; */
}

.an-grid {
  display: grid;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  /* max-width: 100%; */
  grid-template-columns: auto;
  grid-template-rows: 60px auto 200px;
  grid-template-areas: 
    "header"
    "main"
    "footer";
}

.as-img-round {
  background: white;
  border-radius: 50%;
  padding: 25px;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.an-active-sidebar-nav {
  border-left: 4px solid white;
  color: white !important;
}

.an-sidebar-nav {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 25px;
  color: rgb(255, 233, 233);
}

.an-sidebar-nav:hover  {
  color:white
}

.an-sidebar-nav-content {
  display: flex; 
  align-items: center; 
  font-size: 18px; 
  margin: 25px;
}

.an-active-sidebar-nav .an-sidebar-nav-content {
  margin-left: 21px;
}

.an-sidebar-nav-icon {
  margin-right: 10;
}